import React from 'react';
import { Container } from 'react-bootstrap';


function Usados() {
  return (
    <Container>
     <h1>Autos {Usados.name}</h1>
    </Container>
  );
}

export default Usados;
