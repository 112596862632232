import React, { useEffect, useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal, Form, Table } from "react-bootstrap";
import { PDFDocument, pdf } from "@react-pdf/renderer";
import Select from "react-select";  // Importa react-select
import PdfCotizar from "./PdfCotizar";
import CotizacionDetallesModal from "./CotizacionDetallesModal";

const ClienteEmpleadoProductoList = () => {
  const [clientes, setClientes] = useState([]);
  const [cotizaciones, setCotizaciones] = useState([]);
  const [vehiculos, setVehiculos] = useState([]);
  const [empleado, setEmpleado] = useState({
    nombre: "",
    apellido: "",
    id: "",
    rol: "",
  });
  const [selectedCotizacion, setSelectedCotizacion] = useState(null);
  const [formCotizacion, setFormCotizacion] = useState({
    ClienteID: "",
    VehiculoID: "",
    EstadoCotizacion: "Media",
    FechaSeguimiento: "",
  });
  const [showCotizacionModal, setShowCotizacionModal] = useState(false);
  const [showDetallesModal, setShowDetallesModal] = useState(false);
  const [vehiculosLoaded, setVehiculosLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchVehiculos();
        const clientesResponse = await axios.get(
          "https://jpmotorsgt.azurewebsites.net/clientes"
        );
        setClientes(clientesResponse.data);

        const nombre = localStorage.getItem("nombre");
        const apellido = localStorage.getItem("apellido");
        const id = localStorage.getItem("userId");
        const roles = JSON.parse(localStorage.getItem("roles"));

        if (nombre && apellido && id && roles) {
          const rol = roles.includes("Admin") ? "Admin" : "User";
          setEmpleado({ nombre, apellido, id, rol });

          if (vehiculosLoaded) {
            fetchCotizaciones(id, rol);
          }
        } else {
          console.error("Datos del empleado no encontrados en el localStorage");
        }
      } catch (error) {
        console.error("Error al cargar datos:", error);
      }
    };

    fetchData();
  }, [vehiculosLoaded]);

  const fetchCotizaciones = async (empleadoId, rol) => {
    try {
      const response = await axios.get(
        `https://jpmotorsgt.azurewebsites.net/cotizaciones/byEmpleadoId/${empleadoId}`
      );
      let cotizacionesData = response.data;

      if (rol === "User") {
        cotizacionesData = cotizacionesData.filter(
          (cotizacion) => cotizacion.EstadoCotizacion !== "Anulada"
        );
      }

      cotizacionesData = cotizacionesData.map((cotizacion) => {
        const vehiculo = vehiculos.find(
          (v) => v.VehiculoID === cotizacion.VehiculoID
        );
        return {
          ...cotizacion,
          PrecioWeb: vehiculo?.PrecioWeb,
          PrecioGerente: vehiculo?.PrecioGerente,
          PrecioLista: vehiculo?.PrecioLista,
          Anio: vehiculo?.Anio,
          VehiculoDescripcion: `${vehiculo?.Modelo} ${vehiculo?.Marca} ${vehiculo?.Anio}`,
        };
      });

      setCotizaciones(cotizacionesData);
    } catch (error) {
      console.error("Error al obtener cotizaciones:", error);
    }
  };

  const fetchVehiculos = async () => {
    try {
      const response = await axios.get("https://jpmotorsgt.azurewebsites.net/vehiculos");
      setVehiculos(response.data);
      setVehiculosLoaded(true);
    } catch (error) {
      console.error("Error al obtener vehículos:", error);
    }
  };

  const handleAddEditCotizacion = async () => {
    try {
      if (selectedCotizacion) {
        await axios.put("https://jpmotorsgt.azurewebsites.net/cotizaciones", {
          CotizacionID: selectedCotizacion.CotizacionID,
          ...formCotizacion,
          EmpleadoID: empleado.id,
        });
      } else {
        await axios.post("https://jpmotorsgt.azurewebsites.net/cotizaciones", {
          ...formCotizacion,
          EmpleadoID: empleado.id,
          FechaCotizacion: new Date().toISOString().slice(0, 10),
        });
      }
      await fetchCotizaciones(empleado.id, empleado.rol);
      setShowCotizacionModal(false);
      setSelectedCotizacion(null);
      setFormCotizacion({
        ClienteID: "",
        VehiculoID: "",
        EstadoCotizacion: "Media",
        FechaSeguimiento: "",
      });
    } catch (error) {
      console.error("Error al guardar la cotización:", error);
    }
  };

  const handleGeneratePdf = async (cotizacion) => {
    try {
      const [
        imageRes,
        motorRes,
        seguridadRes,
        interiorRes,
        exteriorRes,
        dimensionesRes,
      ] = await Promise.all([
        fetch(`https://jpmotorsgt.azurewebsites.net/vehiculos/${cotizacion.VehiculoID}`),
        fetch(`https://jpmotorsgt.azurewebsites.net/vehiculos/motor/${cotizacion.VehiculoID}`),
        fetch(
          `https://jpmotorsgt.azurewebsites.net/vehiculos/seguridad/${cotizacion.VehiculoID}`
        ),
        fetch(
          `https://jpmotorsgt.azurewebsites.net/vehiculos/interior/${cotizacion.VehiculoID}`
        ),
        fetch(
          `https://jpmotorsgt.azurewebsites.net/vehiculos/exterior/${cotizacion.VehiculoID}`
        ),
        fetch(
          `https://jpmotorsgt.azurewebsites.net/vehiculos/dimensiones/${cotizacion.VehiculoID}`
        ),
      ]);

      const [
        imageData,
        motorData,
        seguridadData,
        interiorData,
        exteriorData,
        dimensionesData,
      ] = await Promise.all([
        imageRes.json(),
        motorRes.json(),
        seguridadRes.json(),
        interiorRes.json(),
        exteriorRes.json(),
        dimensionesRes.json(),
      ]);

      const blob = new Blob([new Uint8Array(imageData.Imagen.data)], {
        type: "image/jpeg",
      });
      const imageUrl = URL.createObjectURL(blob);

      const cliente = clientes.find(
        (c) => c.ClienteID === cotizacion.ClienteID
      );
      const vehiculo = cotizacion.VehiculoDescripcion;

      const pdfDoc = (
        <PdfCotizar
          auto={cotizacion}
          cliente={cliente}
          empleado={empleado}
          imageUrl={imageUrl}
          motorDetails={motorData}
          seguridadDetails={seguridadData}
          interiorDetails={interiorData}
          exteriorDetails={exteriorData}
          dimensionesDetails={dimensionesData}
          precioWeb={cotizacion.PrecioWeb}
          precioGerente={cotizacion.PrecioGerente}
          precioLista={cotizacion.PrecioLista}
        />
      );

      const asPdf = pdf([]);
      asPdf.updateContainer(pdfDoc);
      const blobPdf = await asPdf.toBlob();

      const fileName = `Cotización_${cliente?.Nombre || "Cliente"}_${
        cliente?.Apellido || ""
      }_${vehiculo || "Vehículo"}.pdf`;

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blobPdf);
      link.download = fileName;
      link.click();
    } catch (error) {
      console.error("Error al generar el PDF:", error);
    }
  };

  const handleShowDetalles = (cotizacion) => {
    setSelectedCotizacion(cotizacion);
    setShowDetallesModal(true);
  };

  const handleFormChange = (selectedOption, actionMeta) => {
    setFormCotizacion({
      ...formCotizacion,
      [actionMeta.name]: selectedOption ? selectedOption.value : "",
    });
  };

  return (
    <div className="container-xl">
      {empleado.nombre && (
        <div className="empleado-info">
          <h6>Ejecutivo/a</h6>
          <h2>
            {empleado.nombre} {empleado.apellido}
          </h2>
        </div>
      )}
      <h1 className="my-4">Cotizaciones del Empleado</h1>
      <Button variant="primary" onClick={() => setShowCotizacionModal(true)}>
        Agregar Cotización
      </Button>
      <Table striped bordered hover className="my-4">
        <thead>
          <tr>
            <th>#</th>
            <th>Cliente</th>
            <th>Vehículo</th>
            <th>Fecha Cotización</th>
            <th>Estado</th>
            <th>Fecha Seguimiento</th>
            <th>Acciones</th>
            <th>Cotización</th>
            <th>Detalles</th>
          </tr>
        </thead>
        <tbody>
          {cotizaciones.map((cotizacion, index) => {
            const cliente = clientes.find(
              (c) => c.ClienteID === cotizacion.ClienteID
            );
            return (
              <tr key={cotizacion.CotizacionID}>
                <td>{index + 1}</td>
                <td>
                  {cliente?.Nombre} {cliente?.Apellido}
                  <br />
                  {cliente.Telefono && (
                    <span>Teléfono: {cliente.Telefono}</span>
                  )}
                  <br />
                  {cliente.CorreoElectronico && (
                    <span>Correo: {cliente.CorreoElectronico}</span>
                  )}
                </td>
                <td>
                  {cotizacion.VehiculoDescripcion}
                  <br />
                  {cotizacion.PrecioLista && (
                    <span>
                      Precio: {cotizacion.PrecioLista}
                      <br />
                      Año: {cotizacion.Anio}
                    </span>
                  )}
                </td>
                <td>{cotizacion.FechaCotizacion}</td>
                <td>{cotizacion.EstadoCotizacion}</td>
                <td>{cotizacion.FechaSeguimiento}</td>
                <td>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Button
                      variant="warning"
                      onClick={() => {
                        setSelectedCotizacion(cotizacion);
                        setFormCotizacion({
                          ClienteID: cotizacion.ClienteID,
                          VehiculoID: cotizacion.VehiculoID,
                          EstadoCotizacion: cotizacion.EstadoCotizacion,
                          FechaSeguimiento: cotizacion.FechaSeguimiento,
                        });
                        setShowCotizacionModal(true);
                      }}
                    >
                      Editar
                    </Button>

                    <Button
                      variant="danger"
                      onClick={async () => {
                        if (
                          window.confirm(
                            "¿Estás seguro de que deseas eliminar esta cotización?"
                          )
                        ) {
                          try {
                            await axios.delete(
                              `https://jpmotorsgt.azurewebsites.net/cotizaciones/${cotizacion.CotizacionID}`
                            );
                            await fetchCotizaciones(empleado.id, empleado.rol);
                          } catch (error) {
                            console.error(
                              "Error al eliminar la cotización:",
                              error
                            );
                          }
                        }
                      }}
                    >
                      Eliminar
                    </Button>
                  </div>
                </td>
                <td>
                  <Button
                    variant="success"
                    onClick={() => handleGeneratePdf(cotizacion)}
                  >
                    Generar PDF
                  </Button>
                </td>
                <td>
                  <Button
                    variant="info"
                    onClick={() => handleShowDetalles(cotizacion)}
                  >
                    Detalles
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <Modal
        show={showCotizacionModal}
        onHide={() => setShowCotizacionModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedCotizacion ? "Editar" : "Agregar"} Cotización
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formClienteID">
              <Form.Label>Cliente</Form.Label>
              <Select
                name="ClienteID"
                value={clientes.find(
                  (cliente) => cliente.ClienteID === formCotizacion.ClienteID
                )}
                onChange={handleFormChange}
                options={clientes.map((cliente) => ({
                  value: cliente.ClienteID,
                  label: `${cliente.Nombre} ${cliente.Apellido}`,
                }))}
                isClearable
              />
            </Form.Group>
            <Form.Group controlId="formVehiculoID">
              <Form.Label>Vehículo</Form.Label>
              <Select
                name="VehiculoID"
                value={vehiculos.find(
                  (vehiculo) => vehiculo.VehiculoID === formCotizacion.VehiculoID
                )}
                onChange={handleFormChange}
                options={vehiculos.map((vehiculo) => ({
                  value: vehiculo.VehiculoID,
                  label: `${vehiculo.Modelo} ${vehiculo.Marca} ${vehiculo.Anio}`,
                }))}
                isClearable
              />
            </Form.Group>
            <Form.Group controlId="formEstadoCotizacion">
              <Form.Label>Estado de Cotización</Form.Label>
              <Select
                name="EstadoCotizacion"
                value={{
                  value: formCotizacion.EstadoCotizacion,
                  label: formCotizacion.EstadoCotizacion,
                }}
                onChange={handleFormChange}
                options={[
                  { value: "Alta", label: "Alta" },
                  { value: "Media", label: "Media" },
                  { value: "Baja", label: "Baja" },
                ]}
                isClearable
              />
            </Form.Group>
            <Form.Group controlId="formFechaSeguimiento">
              <Form.Label>Fecha de Seguimiento</Form.Label>
              <Form.Control
                type="date"
                name="FechaSeguimiento"
                value={formCotizacion.FechaSeguimiento}
                onChange={(e) =>
                  setFormCotizacion({
                    ...formCotizacion,
                    FechaSeguimiento: e.target.value,
                  })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowCotizacionModal(false)}
          >
            Cerrar
          </Button>
          <Button variant="primary" onClick={handleAddEditCotizacion}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>

      {selectedCotizacion && (
        <CotizacionDetallesModal
          show={showDetallesModal}
          cotizacion={selectedCotizacion}
          onHide={() => setShowDetallesModal(false)}
        />
      )}
    </div>
  );
};

export default ClienteEmpleadoProductoList;
